import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';
import { ElementMapModelDTO, RouteDTO, RouteTableDTO } from '../models/route';

@Injectable({ providedIn: 'root' })
export class RouteService extends  BaseService<RouteDTO, RouteTableDTO> {
    protected _url = 'api/Route/';

    public tryToChangeRouteStatus(id: number): Observable<any> {
        return this._http.put(environment.API + this._url + 'tryToChangeRouteStatus/' + id, null);
    }

    public appointInspector(id: number, userId: number): Observable<any> {
        return this._http.put(environment.API + this._url + 'appointInspector/' + id + '/' + userId, null);
    }

    public getMapInfo(id: number): Observable<any> {
        return this._http.get(environment.API + this._url + 'getMapInfo/' + id);
    }

    public getInfo(id: number): Observable<any> {
        return this._http.get(environment.API + this._url  + id + '/info');
    }

    public sendRoute(id: number): Observable<any> {
        return this._http.put(environment.API + this._url + 'sendRoute/' + id, null);
    }

    public setCoords(id: number): Observable<any> {
        return this._http.put(environment.API + this._url + 'setCoords/' + id, null);
    }

    public getRoutesByAct(actTypeId: number): Observable<any> {
        return this._http.get(environment.API + this._url + 'getRoutesByAct/' + actTypeId);
    }

    public sendData(id: number): Observable<any> {
        return this._http.put(environment.API + this._url + 'sendData/' + id, null);
    }    

    public getRouteMapInfo(id: number): Observable<ElementMapModelDTO[]> {
        return this._http.get<ElementMapModelDTO[]>(environment.API + this._url + 'getRouteMapInfo/' + id);
    }    
}