import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddressNameDTO } from '../models/location';

@Injectable({ providedIn: 'root' })
export class LocationService {
    protected _url = 'api/Location/';

    constructor(protected _http: HttpClient) { }

    // Получение районов - 1 Уровень
    public getProvinces(): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getProvinces/');
    }
    // Получение районов ЮЛ
    public getProvincesEe(): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getProvincesEe/');
    }

    // Получение городов - 2 Уровень
    public getTowns(): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getTowns/');
    }
    // Получение городов ЮЛ
    public getTownsEe(): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getTownsEe/');
    }

    // Получение городов по районам - 1->2 уровень
    public getTownsByProvince(provinceId: number): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getTownsByProvince/' + provinceId);
    }
    // Получение городов по районам ЮЛ
    public getTownsByProvinceEe(provinceId: number): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getTownsByProvinceEe/' + provinceId);
    }

    // Получение улиц по городам - 2->4 уровень
    public getStreetsByTown(townId: number): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getStreetsByTown/' + townId);
    }
    // Получение улиц по городам ЮЛ
    public getStreetsByTownEe(townId: number): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getStreetsByTownEe/' + townId);
    }

    // Получение улиц по нас. пунктам - 3->4 уровень
    public getStreetsByMun(munId: number): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getStreetsByMun/' + munId);
    }
    // Получение улиц по нас. пунктам ЮЛ
    public getStreetsByMunEe(munId: number): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getStreetsByMunEe/' + munId);
    }

    // Получение нас. пунктов по городам - 2->3 уровень
    public getMunByTown(townId: number): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getMunByTown/' + townId);
    }
    // Получение нас. пунктов по городам ЮЛ
    public getMunByTownEe(townId: number): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getMunByTownEe/' + townId);
    }    

    // Получение нас пунктов по районам - 1->3 уровень
    public getMunByProvince(provinceId: number): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getMunByProvince/' + provinceId);
    }

    // Получение нас пунктов по районам ЮЛ
    public getMunByProvinceEe(provinceId: number): Observable<AddressNameDTO[]> {
        return this._http.get<AddressNameDTO[]>(environment.API + this._url + 'getMunByProvinceEe/' + provinceId);
    }    
}

