import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PersonalAccountDTO } from '../models/location';
import { BuildingTypeDTO } from '../models/route';
import { RouteHouseFilterDTO, SubscrDTO } from '../models/subscr';

@Injectable({ providedIn: 'root' })
export class SubscrService {
    protected _url = 'api/Subscr/';

    constructor(protected _http: HttpClient) { }

    public get(id: number): Observable<SubscrDTO> {
        return this._http.get<SubscrDTO>(environment.API + this._url + id);
    }

    public getBuildingsByStreet(streetId: number): Observable<BuildingTypeDTO[]> {
        return this._http.get<BuildingTypeDTO[]>(environment.API + this._url + 'getBuildings/' + streetId);
    }

    public getBuildingsByMun(munId: number): Observable<BuildingTypeDTO[]> {
        return this._http.get<BuildingTypeDTO[]>(environment.API + this._url + 'getBuildingsByMun/' + munId);
    }

    public getPersonalAccounts(dto: RouteHouseFilterDTO): Observable<PersonalAccountDTO[]> {
        return this._http.put<PersonalAccountDTO[]>(environment.API + this._url + 'getPersonalAccounts', dto);
    }

    public getPersonalAccountsByMun(dto: RouteHouseFilterDTO): Observable<PersonalAccountDTO[]> {
        return this._http.put<PersonalAccountDTO[]>(environment.API + this._url + 'getPersonalAccountsByMun', dto);
    }

    public getPersonalAccountsByHouses(dto: RouteHouseFilterDTO): Observable<PersonalAccountDTO[]> {
        return this._http.put<PersonalAccountDTO[]>(environment.API + this._url + 'getPersonalAccountsByHouses', dto);
    }

    public getPersonalAccountsByMuns(dto: RouteHouseFilterDTO): Observable<PersonalAccountDTO[]> {
        return this._http.put<PersonalAccountDTO[]>(environment.API + this._url + 'getPersonalAccountsByMun', dto);
    }
}

