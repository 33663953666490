import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IRoleModel } from './roles.service';

@Injectable({ providedIn: 'root' })
export class UsersService {
  private baseUrl = environment.API + 'api/admin/users';
  constructor(private http: HttpClient) { }

  /** Возвращет список пользователей */
  public getUserListItems(): Observable<IUser[]> {
    return this.http.get<IUser[]>(this.baseUrl);
  }

  /** Список ролей для назначения пользователю */
  public getRoles(): Observable<IRoleModel[]> {
    const url = this.baseUrl + '/roles';
    return this.http.get<IRoleModel[]>(url);
  }

  /** Создание пользователя */
  public createUser(user: IUser): Observable<any> {
    return this.http.post(this.baseUrl, user);
  }

  /** Обновление данных пользователя */
  public updateUser(user: IUser): Observable<any> {
    return this.http.put(this.baseUrl, user);
  }

  /** Блокировка пользователя по логину */
  public setUserLockByLogin(login: string, isLock: boolean): Observable<any> {
    const url = this.baseUrl + '/locks?login=' + login + '&isLock=' + isLock;
    return this.http.put(url, {});
  }

  /** Смена пароля пользователя по логину */
  public updateUserPwd(login: string, pwd: string): Observable<any> {
    const url = this.baseUrl + '/passwords';
    return this.http.put(url, { login, newPwd: pwd });
  }

  /** Удаление пользователя по логину */
  public removeUserByLogin(login: string): Observable<any> {
    const url = this.baseUrl + '?login=' + login;
    return this.http.delete(url);
  }
}

export enum UserTypes {
  Default = 0,
  Domain = 1,
  DomainGroup = 2,
}

/** Пользователь */
export interface IUser {
  id: number;
  login: string;
  pwd: string | null;
  email: string | null;
  phone: string | null;
  receiveEmalNotify: boolean;
  lastName: string | null;
  firstName: string | null;
  middleName: string | null;
  fullName: string | null;
  post: string | null;
  type: UserTypes;
  isLock: boolean;
  roles: IRoleModel[];
  teamName: string;
}
