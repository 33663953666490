import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

// Базовый класс сервиса взаимодействия с сущностями
@Injectable({
  providedIn: 'root'
})
export abstract class BaseService<TEntityDTO extends IEntityId, TEntityTableDTO extends IEntityId>
  implements ICRUDService<TEntityDTO, TEntityTableDTO> {
  // tslint:disable:variable-name
  protected _url = '/api/';
  private _pageUrl = 'page/';

  constructor(protected _http: HttpClient) { }

  public get(id: number): Observable<TEntityDTO> {
    return this._http.get<TEntityDTO>(environment.API + this._url + id);
  }

  public getAll(): Observable<TEntityTableDTO[]> {
    return this._http.get<TEntityTableDTO[]>(environment.API + this._url);
  }

  public getPagination(first: number, rows: number): Observable<IDataResult<TEntityTableDTO>> {
    return this._http.get<IDataResult<TEntityTableDTO>>(environment.API + this._url + this._pageUrl + first + '/' + rows);
  }

  public create(entity: TEntityDTO): Observable<any> {
    return this._http.post(environment.API + this._url, entity);
  }

  public update(entity: TEntityDTO): Observable<any> {
    return this._http.put(environment.API + this._url + entity.id, entity);
  }

  public delete(id: number): Observable<any> {
    return this._http.delete(environment.API + this._url + id);
  }

  public multipleDelete(ids: number[]): Observable<any> {
    return this._http.put(environment.API + this._url + 'multipleDelete', ids);
  }
}

export interface IEntityId {
  id: number;
}

export interface ICRUDService<TEntityDTO, TEntityTableDTO> {
  get(id: number): Observable<TEntityDTO>;
  getAll(): Observable<TEntityTableDTO[]>;
  create(entity: TEntityDTO): Observable<any>;
  update(entity: TEntityDTO): Observable<any>;
  delete(id: number): Observable<any>;
}

export interface IDataResult<T> {
  data: T[];
  totalRecords: number;
}
