import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

/** Сервис для работы с Excel */
@Injectable({
  providedIn: 'root'
})

export class ExcelService {
  constructor() { }

  /** Экспорт данных в Excel
   * @param data - данные для отчета
   * @param selectedColumns - отображаемые колонки
   * @param moduleName - название модуля из которого делается отчет
   */
  public exportToExcel(data, selectedColumns, moduleName): void {
    const newArr = [];                  // массив данных в новой последовательности
    const newModel = {};                // создание новой модели данных на основе расположения колонок в таблице
    const newColunmNameForExp = [];     // наименования колонок для экспорта
    selectedColumns.forEach(x => {
      newModel[x.field] = null;
      newColunmNameForExp.push(x.header);
    });

    const fieldForDell = [];
    const newModelFields = Object.keys(newModel);
    const oldModelFields = Object.keys(data[0]);

    oldModelFields.forEach(z => {  // находим скрытые поля и добавляем их в массив
      if (newModelFields.some(x => x === z) === false) {
        fieldForDell.push(z);
      }
    });

    const elementsCopy = data.map(x => Object.assign({}, x));  // удаляем скрытые поля
    fieldForDell.forEach(x => {
      elementsCopy.forEach(z => {
        delete z[x];
      });
    });

    elementsCopy.forEach(x => {
      const copy = Object.assign({}, newModel); // копирование модели новой последовательности полей
      newArr.push(Object.assign(copy, x));      // заполение массива данных в новой последовательности полей
    });

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(newArr);
      xlsx.utils.sheet_add_aoa(worksheet, [newColunmNameForExp]);
      const workbook = { Sheets: { [moduleName]: worksheet }, SheetNames: [moduleName] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, moduleName);
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
